<template>
	<!-- Start Template Root Div -->
	<div
		class="h-screen bg-gray-100 bg-cover"
		:style="{
			backgroundImage:
				'url(' +
					require(`@/assets/img/wave-availability-screen.jpeg`) +
				')'
		}"
	>
		<WaveLoader v-if="fetchingSurfAvailabilityLoading" />

		<!-- Start Underlay (Darken the Underlying Background Image Slightly) -->
		<div
			class="absolute z-10 inset-0 h-full w-full bg-gray-800 bg-opacity-10"
		></div>
		<!-- End Underlay (Darken the Underlying Background Image Slightly) -->

		<!-- Main Availability Board -->
		<main
			id="main"
			role="main"
			class="
				w-full h-full flex flex-col 
				backdrop-filter backdrop-blur-sm 
				relative z-20
			"
		>
			<header class="w-full relative" role="header">
				<nav
					role="navigation"
					class="bg-white bg-opacity-20 backdrop-filter backdrop-blur-xl relative text-white"
				>
					<div
						class="relative flex items-center justify-between px-10 py-8"
					>
						<div
							class="flex-1 flex items-center justify-between sm:items-stretch"
						>
							<div class="flex-shrink-0 flex items-center">
								<WaveLogo class="w-24 h-24 opacity-90" />
							</div>
							<div class="flex-shrink-0 flex items-center">
								<h1 class="text-5xl font-bold">
									Surf Again Today!
								</h1>
							</div>
							<div class="w-10 opacity-0"></div>
						</div>
					</div>
				</nav>
			</header>

			<!-- Start Availability Grid -->
			<div
				id="surf-availability-pages"
				class="relative flex-auto overflow-hidden"
			>
				<!-- - Iterate over surfAvailabilityPages - -->
				<div
					v-for="(surfAvailabilityPage,
					surfAvailabilityPageIndex) in surfAvailabilityPages"
					:key="`surf-availability-page-${surfAvailabilityPageIndex}`"
					class="
						absolute top-0 left-0 
						w-full flex space-x-5 p-10 
						transform duration-200
					"
					:class="{
						'-translate-x-full':
							surfAvailabilityPageIndex <
							surfAvailabilityPageVisible,
						'translate-x-0':
							surfAvailabilityPageIndex ==
							surfAvailabilityPageVisible,
						'translate-x-full':
							surfAvailabilityPageIndex >
							surfAvailabilityPageVisible
					}"
				>
					<!-- - Iterate over the surfAvailability within the surfAvailabilityPage - -->
					<div
						v-for="(surfAvailability,
						surfAvailabilityIndex) in surfAvailabilityPage"
						:key="
							`surf-availability-page-${surfAvailabilityPageIndex}-surf-availability-${surfAvailabilityIndex}`
						"
						class="flex-auto max-w-3xl"
						aria-role="column-display"
						:aria-label="
							`Availability for ${surfAvailability.name} Sessions`
						"
					>
						<!-- Start Availability Session Heading -->
						<div
							class="flex items-center justify-center px-5 py-3 border border-transparent text-2xl font-bold uppercase tracking-wide rounded-full text-white bg-gray-900 mb-5"
						>
							{{
								surfAvailability.name.includes("Surf")
									? surfAvailability.name
									: `${surfAvailability.name} Surf`
							}}
						</div>
						<!-- End Availability Session Heading -->

						<!-- Start Surf Session Availability Cards -->
						<div class="grid grid-cols-1 gap-y-5 min-w-full">
							<div
								v-bind:key="index"
								v-for="(surfAvailabilitySession,
								index) in surfAvailability.sessions.filter(
									session =>
										session.availability > 0 &&
										surfSessionClosingTime(
											session.startTime
										) > 0
								)"
								class="col-span-1"
							>
								<transition
									appear
									name="custom-classes-transition"
									enter-active-class="animated fadeInUp"
									leave-active-class="animated fadeOutUp"
								>
									<div
										v-show="
											!(
												surfSessionClosingTime(
													surfAvailabilitySession.startTime
												) < 30000
											)
										"
										class="h-56 max-w-sm w-full lg:max-w-full lg:flex bg-white rounded-xl relative"
									>
										<div
											v-if="
												surfSessionClosingTime(
													surfAvailabilitySession.startTime
												) < 0
											"
											class="absolute w-full h-full bg-white rounded-xl"
											style="opacity: 0.9"
										>
											<div
												class="flex items-center w-full h-full"
											>
												<div
													class="flex-1 text-center px-4 py-2"
												>
													<p
														class="text-gray-800 text-3xl font-bold leading-normal tracking-widest uppercase"
													>
														Bookings Closed
													</p>
												</div>
											</div>
										</div>
										<div
											class="py-4 px-12 lg:w-full flex flex-col justify-between leading-normal"
										>
											<div class="my-3 mr-auto">
												<p
													class="text-gray-500 font-bold leading-normal tracking-widest uppercase"
												>
													Surf Starting At
												</p>
												<div
													class="text-gray-800 font-semibold leading-tight text-base"
												>
													<span class="text-6xl">
														{{
															surfAvailabilitySession.startTime
																| moment(
																	"utc",
																	"h:mm"
																)
														}}
													</span>
													<span class="text-3xl">
														{{
															surfAvailabilitySession.startTime
																| moment(
																	"utc",
																	"A"
																)
														}}
													</span>
												</div>

												<p
													class="text-gray-500 text-md font-bold leading-normal tracking-widest uppercase"
												>
													To
												</p>

												<div
													class="text-gray-800 font-semibold leading-tight text-base"
												>
													<span class="text-2xl">
														{{
															surfAvailabilitySession.startTime
																| moment(
																	"utc",
																	"add",
																	"1 hour",
																	"h:mm"
																)
														}}
													</span>
													<span class="text-md">
														{{
															surfAvailabilitySession.startTime
																| moment(
																	"utc",
																	"add",
																	"1 hour",
																	"A"
																)
														}}
													</span>
												</div>
											</div>
											<div
												v-if="
													surfSessionClosingTime(
														surfAvailabilitySession.startTime
													) > 0
												"
												class="flex items-center"
											>
												<p
													class="text-sm text-red-500 font-bold leading-normal tracking-widest uppercase flex items-center"
												>
													<svg
														class="fill-current text-red-500 w-3 h-3 mr-2"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path
															d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
														/>
													</svg>
													Bookings close in
													{{
														surfSessionClosingTime(
															surfAvailabilitySession.startTime
														) | duration("humanize")
													}}
												</p>
											</div>
											<div
												v-else
												class="flex items-center"
											>
												<p
													class="text-sm text-red-500 font-bold leading-normal tracking-widest uppercase flex items-center"
												>
													<svg
														class="fill-current text-red-500 w-3 h-3 mr-2"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path
															d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
														/>
													</svg>
													Bookings Closed
												</p>
											</div>
										</div>

										<!-- - Right Hand Session Availability Counter - -->
										<div
											class="
												w-48 h-full p-6 flex-none 
												flex flex-col content-center justify-center 
												bg-turquoise-500 text-center text-gray-700
												rounded-xl rounded-tl-none
											"
										>
											<h2
												class="text-white font-semibold text-7xl"
											>
												{{
													surfAvailabilitySession.availability
												}}
											</h2>
											<h3
												class="text-white font-semibold leading-normal tracking-widest uppercase"
											>
												{{
													surfAvailabilityText(
														surfAvailabilitySession.availability
													)
												}}
											</h3>
										</div>
									</div>
								</transition>
							</div>
						</div>
						<!-- End Surf Session Availability Cards -->
					</div>
				</div>
			</div>
			<!-- End Availability Grid -->
		</main>
		<!-- Main Availability Board -->

		<footer class="w-full fixed bottom-0 z-30 m-6">
			<div
				v-show="!fetchingSurfAvailabilityLoading"
				class="w-full h-20 m-auto relative"
			>
				<div class="w-full max-w-7xl mx-auto">
					<p
						class="text-4xl text-center font-bold text-white uppercase tracking-wide"
					>
						<!-- - 50% discount on another surf if you have surfed today - -->
					</p>
				</div>
			</div>
		</footer>
	</div>
	<!-- End Template Root Div -->
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 300 }] */
	/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */

	import { mapState, mapActions } from "vuex";

	// import WaveAlert from '@/components/WaveAlert.vue';
	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		components: {
			// Initialise components here:
			WaveLoader,
			WaveLogo
		},
		// Data is an arrow function returning an object:
		data: () => ({
			date: new Date(),
			fetchedTimestamp: "",
			bookingBuffer: 900000,
			surfAvailabilityPages: [],
			surfAvailabilityPageVisible: 0
		}),
		// Computed Properties:
		computed: {
			...mapState("availability", [
				"activeSurfAvailability",
				"fetchingSurfAvailabilityLoading",
				"fetchingSurfAvailabilitySuccess"
			])
		},
		// Lifecycle Hooks:
		created() {
			this.fetchedTimestamp = new Date(
				JSON.parse(
					localStorage.getItem("ACTIVE_SURF_AVAILABILITY_TIMESTAMP")
				)
			);

			setInterval(this.updateTime, 1000);

			this.date = new Date();

			setInterval(this.fetchSurfAvailabilityAction(), 1000);

			setInterval(this.updateSurfAvailabilityPageVisible, 5000);
		},
		watch: {
			activeSurfAvailability(activeSurfAvailability) {
				// Filter the computed SurfAvailability state
				const surfAvailabilities = activeSurfAvailability.filter(
					surfAvailability => {
						// Check that the Availity Object's Sessions are all available
						const sessionsValid = surfAvailability.sessions.filter(
							session =>
								session.availability > 0 &&
								this.surfSessionClosingTime(session.startTime) >
									0
						);

						// Return a bool based on the number of available sessions
						return sessionsValid.length > 0;
					}
				);

				// Reset SurfAvailabilityPages
				this.surfAvailabilityPages = [];

				// Set SurfAvailabilityPages
				// Thanks to https://stackoverflow.com/questions/8495687/split-array-into-chunks
				const chunkSize = 4;
				for (let x = 0; x < surfAvailabilities.length; x += chunkSize)
					this.surfAvailabilityPages.push(
						surfAvailabilities.slice(x, x + chunkSize)
					);
			}
		},
		// Component Methods:
		methods: {
			// Standard Vanilla JS Functions:
			updateTime() {
				this.date = new Date(
					new Date().getTime() -
						new Date().getTimezoneOffset() * 60 * 1000
				);
			},
			// Vuex Mapped Actions from availability Namespace:
			...mapActions([
				"availability/fetchSurfAvailability",
				"availability/fetchSurfAvailabilityFromLocalStorage"
			]),
			// Component Relevant Functions:
			fetchSurfAvailabilityAction() {
				this["availability/fetchSurfAvailability"]();
			},
			fetchSurfAvailabilityFromLocalStorage() {
				this["availability/fetchSurfAvailabilityFromLocalStorage"]();
			},
			fetchSurfAvailabilityHardRefresh() {
				let confirm = window.confirm(
					"Are you sure? (This may take some time)"
				);

				if (confirm) {
					this.fetchActiveSurfAvailabilityAction();
				}
			},
			surfAvailabilitySessionExpired(startTime) {
				if (new Date() > new Date(startTime)) {
					return true;
				}
				return false;
			},
			surfSessionClosingTime(startTime) {
				var surfSessionClosingIn =
					this.$moment(startTime).utc() -
					this.date -
					this.bookingBuffer;
				return surfSessionClosingIn;
			},
			surfAvailabilityText(availability) {
				if (availability == 1) {
					return "Place Left";
				}
				return "Places Left";
			},
			updateSurfAvailabilityPageVisible() {
				if (
					this.surfAvailabilityPageVisible + 1 <
					this.surfAvailabilityPages.length
				)
					this.surfAvailabilityPageVisible++;
				else this.surfAvailabilityPageVisible = 0;
			}
		}
	};
</script>
