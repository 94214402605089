var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen bg-gray-100 bg-cover",style:({
		backgroundImage:
			'url(' +
				require(`@/assets/img/wave-availability-screen.jpeg`) +
			')'
	})},[(_vm.fetchingSurfAvailabilityLoading)?_c('WaveLoader'):_vm._e(),_c('div',{staticClass:"absolute z-10 inset-0 h-full w-full bg-gray-800 bg-opacity-10"}),_c('main',{staticClass:"w-full h-full flex flex-col backdrop-filter backdrop-blur-sm relative z-20",attrs:{"id":"main","role":"main"}},[_c('header',{staticClass:"w-full relative",attrs:{"role":"header"}},[_c('nav',{staticClass:"bg-white bg-opacity-20 backdrop-filter backdrop-blur-xl relative text-white",attrs:{"role":"navigation"}},[_c('div',{staticClass:"relative flex items-center justify-between px-10 py-8"},[_c('div',{staticClass:"flex-1 flex items-center justify-between sm:items-stretch"},[_c('div',{staticClass:"flex-shrink-0 flex items-center"},[_c('WaveLogo',{staticClass:"w-24 h-24 opacity-90"})],1),_vm._m(0),_c('div',{staticClass:"w-10 opacity-0"})])])])]),_c('div',{staticClass:"relative flex-auto overflow-hidden",attrs:{"id":"surf-availability-pages"}},_vm._l((_vm.surfAvailabilityPages),function(surfAvailabilityPage,surfAvailabilityPageIndex){return _c('div',{key:`surf-availability-page-${surfAvailabilityPageIndex}`,staticClass:"absolute top-0 left-0 w-full flex space-x-5 p-10 transform duration-200",class:{
					'-translate-x-full':
						surfAvailabilityPageIndex <
						_vm.surfAvailabilityPageVisible,
					'translate-x-0':
						surfAvailabilityPageIndex ==
						_vm.surfAvailabilityPageVisible,
					'translate-x-full':
						surfAvailabilityPageIndex >
						_vm.surfAvailabilityPageVisible
				}},_vm._l((surfAvailabilityPage),function(surfAvailability,surfAvailabilityIndex){return _c('div',{key:`surf-availability-page-${surfAvailabilityPageIndex}-surf-availability-${surfAvailabilityIndex}`,staticClass:"flex-auto max-w-3xl",attrs:{"aria-role":"column-display","aria-label":`Availability for ${surfAvailability.name} Sessions`}},[_c('div',{staticClass:"flex items-center justify-center px-5 py-3 border border-transparent text-2xl font-bold uppercase tracking-wide rounded-full text-white bg-gray-900 mb-5"},[_vm._v(" "+_vm._s(surfAvailability.name.includes("Surf") ? surfAvailability.name : `${surfAvailability.name} Surf`)+" ")]),_c('div',{staticClass:"grid grid-cols-1 gap-y-5 min-w-full"},_vm._l((surfAvailability.sessions.filter(
								session =>
									session.availability > 0 &&
									_vm.surfSessionClosingTime(
										session.startTime
									) > 0
							)),function(surfAvailabilitySession,index){return _c('div',{key:index,staticClass:"col-span-1"},[_c('transition',{attrs:{"appear":"","name":"custom-classes-transition","enter-active-class":"animated fadeInUp","leave-active-class":"animated fadeOutUp"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
										!(
											_vm.surfSessionClosingTime(
												surfAvailabilitySession.startTime
											) < 30000
										)
									),expression:"\n\t\t\t\t\t\t\t\t\t\t!(\n\t\t\t\t\t\t\t\t\t\t\tsurfSessionClosingTime(\n\t\t\t\t\t\t\t\t\t\t\t\tsurfAvailabilitySession.startTime\n\t\t\t\t\t\t\t\t\t\t\t) < 30000\n\t\t\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\t\t"}],staticClass:"h-56 max-w-sm w-full lg:max-w-full lg:flex bg-white rounded-xl relative"},[(
											_vm.surfSessionClosingTime(
												surfAvailabilitySession.startTime
											) < 0
										)?_c('div',{staticClass:"absolute w-full h-full bg-white rounded-xl",staticStyle:{"opacity":"0.9"}},[_c('div',{staticClass:"flex items-center w-full h-full"},[_c('div',{staticClass:"flex-1 text-center px-4 py-2"},[_c('p',{staticClass:"text-gray-800 text-3xl font-bold leading-normal tracking-widest uppercase"},[_vm._v(" Bookings Closed ")])])])]):_vm._e(),_c('div',{staticClass:"py-4 px-12 lg:w-full flex flex-col justify-between leading-normal"},[_c('div',{staticClass:"my-3 mr-auto"},[_c('p',{staticClass:"text-gray-500 font-bold leading-normal tracking-widest uppercase"},[_vm._v(" Surf Starting At ")]),_c('div',{staticClass:"text-gray-800 font-semibold leading-tight text-base"},[_c('span',{staticClass:"text-6xl"},[_vm._v(" "+_vm._s(_vm._f("moment")(surfAvailabilitySession.startTime, "utc", "h:mm" ))+" ")]),_c('span',{staticClass:"text-3xl"},[_vm._v(" "+_vm._s(_vm._f("moment")(surfAvailabilitySession.startTime, "utc", "A" ))+" ")])]),_c('p',{staticClass:"text-gray-500 text-md font-bold leading-normal tracking-widest uppercase"},[_vm._v(" To ")]),_c('div',{staticClass:"text-gray-800 font-semibold leading-tight text-base"},[_c('span',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm._f("moment")(surfAvailabilitySession.startTime, "utc", "add", "1 hour", "h:mm" ))+" ")]),_c('span',{staticClass:"text-md"},[_vm._v(" "+_vm._s(_vm._f("moment")(surfAvailabilitySession.startTime, "utc", "add", "1 hour", "A" ))+" ")])])]),(
												_vm.surfSessionClosingTime(
													surfAvailabilitySession.startTime
												) > 0
											)?_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"text-sm text-red-500 font-bold leading-normal tracking-widest uppercase flex items-center"},[_c('svg',{staticClass:"fill-current text-red-500 w-3 h-3 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"}})]),_vm._v(" Bookings close in "+_vm._s(_vm._f("duration")(_vm.surfSessionClosingTime( surfAvailabilitySession.startTime ),"humanize"))+" ")])]):_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"text-sm text-red-500 font-bold leading-normal tracking-widest uppercase flex items-center"},[_c('svg',{staticClass:"fill-current text-red-500 w-3 h-3 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"}})]),_vm._v(" Bookings Closed ")])])]),_c('div',{staticClass:"w-48 h-full p-6 flex-none flex flex-col content-center justify-center bg-turquoise-500 text-center text-gray-700 rounded-xl rounded-tl-none"},[_c('h2',{staticClass:"text-white font-semibold text-7xl"},[_vm._v(" "+_vm._s(surfAvailabilitySession.availability)+" ")]),_c('h3',{staticClass:"text-white font-semibold leading-normal tracking-widest uppercase"},[_vm._v(" "+_vm._s(_vm.surfAvailabilityText( surfAvailabilitySession.availability ))+" ")])])])])],1)}),0)])}),0)}),0)]),_c('footer',{staticClass:"w-full fixed bottom-0 z-30 m-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fetchingSurfAvailabilityLoading),expression:"!fetchingSurfAvailabilityLoading"}],staticClass:"w-full h-20 m-auto relative"},[_vm._m(1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-shrink-0 flex items-center"},[_c('h1',{staticClass:"text-5xl font-bold"},[_vm._v(" Surf Again Today! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full max-w-7xl mx-auto"},[_c('p',{staticClass:"text-4xl text-center font-bold text-white uppercase tracking-wide"})])
}]

export { render, staticRenderFns }